<script setup lang="ts">
import { Ref, ref } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { OutlineDelete } from '@ankor-io/icons/outline'
import { SolidChevronDown, SolidCircleQuestion } from '@ankor-io/icons/solid'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'

import { DisclaimersSectionData } from './types/types'

type Props = {
  id: string
  data: DisclaimersSectionData
  layout: LayoutTemplate
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<DisclaimersSectionData>): void
}>()

const openIndex: Ref<number | null> = ref(null)
const toggleAccordion = (index: number): void => {
  if (openIndex.value === index) {
    openIndex.value = null
  } else {
    openIndex.value = index
  }
}

const update = (event: { field: string; value: string; index: number }): void => {
  const data: DisclaimersSectionData = ObjectUtil.deepCopy(props.data)
  if (event.field === 'type') {
    data.disclaimers[event.index].type = event.value
  } else {
    data.disclaimers[event.index].disclaimer = event.value
  }

  emit('update:value', { sectionId: props.id, data })
}

const deleteDisclaimer = (index: number): void => {
  const data: DisclaimersSectionData = ObjectUtil.deepCopy(props.data)
  data.disclaimers.splice(index, 1)

  emit('update:value', { sectionId: props.id, data })
}
</script>
<template>
  <div>
    <div v-if="props.layout.type === 'open'" id="disclaimer-open" class="flex flex-col gap-y-14">
      <div v-for="(disclaimer, i) in props.data.disclaimers" class="flex gap-x-5" :key="disclaimer.id">
        <div class="flex flex-col gap-y-5">
          <div class="flex gap-x-2.5">
            <SolidCircleQuestion class="size-5 flex-shrink-0 icon-theme-subheading" />
            <h6>
              <SingleLineTextEditor
                class="font-medium"
                placeholder="Add a disclaimer title"
                :value="disclaimer.type"
                @update:value="update({ field: 'type', value: $event, index: i })"
              />
            </h6>
          </div>
          <MultiLineTextEditor
            class="font-medium"
            placeholder="Add a disclaimer description"
            :value="disclaimer.disclaimer"
            @update:value="update({ field: 'disclaimer', value: $event, index: i })"
          />
        </div>
        <button @click="deleteDisclaimer(i)">
          <OutlineDelete class="shrink-0 w-4 cursor-pointer stroke-red-500" />
        </button>
      </div>
    </div>

    <!-- Accordion -->
    <div v-else id="disclaimer-accordion" class="flex flex-col">
      <template v-for="(disclaimer, i) in props.data.disclaimers" :key="`${i}-${disclaimer.id}`">
        <div class="flex gap-x-5">
          <div class="w-full">
            <div
              class="peer flex justify-between items-center w-full p-5 font-medium text-left border border-gray-200 hover:bg-gray-100"
              aria-expanded="true"
              :class="{
                'rounded-t-xl': i === 0,
                'rounded-b-xl': i === props.data.disclaimers.length - 1 && openIndex !== i,
                'bg-gray-100': openIndex === i,
              }"
              @click="toggleAccordion(i)"
            >
              <div class="flex gap-x-2.5">
                <SolidCircleQuestion class="size-5 flex-shrink-0 transition-all icon-theme-subheading" />
                <h6>
                  <SingleLineTextEditor
                    class="font-medium"
                    placeholder="Add a disclaimer title"
                    :value="disclaimer.type"
                    @click.stop
                    @update:value="update({ field: 'type', value: $event, index: i })"
                  />
                </h6>
              </div>
              <SolidChevronDown
                class="w-5 icon-fill-theme-subheading transition-all duration-500"
                :class="{ 'rotate-180': openIndex === i }"
              />
            </div>
            <div
              v-show="openIndex === i"
              class="p-5 border border-gray-200 transition-all duration-300 ease-out overflow-hidden"
            >
              <MultiLineTextEditor
                class="font-medium"
                placeholder="Add a disclaimer description"
                :value="disclaimer.disclaimer"
                @update:value="update({ field: 'disclaimer', value: $event, index: i })"
              />
            </div>
          </div>
          <button @click="deleteDisclaimer(i)">
            <OutlineDelete class="shrink-0 w-4 cursor-pointer stroke-red-500" />
          </button>
        </div>
      </template>
    </div>
  </div>
</template>
