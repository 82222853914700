<script lang="ts" setup>
/**
 * ###############################################
 * ################### Input #####################
 * id - Proposal ID
 * uri - URI of the vessel entity
 * data - {@VesselHeroData} - Vessel hero data
 * layout - {@LayoutTemplate} - Layout template
 * ################### Output ####################
 * Renders vessel hero section (non-editable)
 * ###############################################
 */
import { Ref, ref } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import ImageCarouselModal from '@ankor-io/blocks/components/ImageCarouselModal/ImageCarouselModal.vue'
import { OutlinePhoto } from '@ankor-io/icons/outline'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'
import { VesselHeroData } from '@/sections/vessel/hero/types'

type Props = {
  id: string
  proposalUri: string
  data: VesselHeroData
  layout: LayoutTemplate
}

const props = defineProps<Props>()

const showCarouselModal: Ref<boolean> = ref(false)
</script>
<template>
  <section class="antialiased text-center" v-if="props.data">
    <!-- Vessel name -->
    <h1 class="text-2xl @sm:text-3xl font-bold mb-4">
      <SingleLineTextEditor :is-editable="false" :value="props.data.name" placeholder="Vessel name" />
    </h1>

    <!-- Vessel type -->
    <h5 class="text-xl">
      <SingleLineTextEditor :is-editable="false" :value="props.data.type" placeholder="Vessel Type" />
    </h5>

    <div class="flex justify-center mt-4">
      <hr class="border-t-2 my-1 border-theme-primary w-28" />
    </div>

    <!-- Featured Items -->
    <div
      class="relative z-10 mx-3 mt-3 select-none"
      :class="props.data.length || props.data.sleeps || props.data.cabins || props.data.builder ? 'mt-4' : 'mt-16'"
    >
      <dl
        v-if="props.data.length || props.data.sleeps || props.data.cabins || props.data.builder"
        class="inline-flex flex-wrap bg-white justify-center p-4 shadow-lg gap-2 @sm:gap-8 rounded-lg border border-gray-200 dark:border-gray-600"
      >
        <div v-if="props.data.length" class="flex flex-col justify-center items-center">
          <p>Length</p>
          <h1 class="!text-base @sm:!text-2xl font-bold">
            <SingleLineTextEditor :is-editable="false" :value="props.data.length" />
          </h1>
        </div>
        <div v-if="props.data.sleeps" class="flex flex-col justify-center items-center">
          <p>Sleeps</p>
          <h1 class="!text-base @sm:!text-2xl font-bold">
            <SingleLineTextEditor :is-editable="false" :value="props.data.sleeps" />
          </h1>
        </div>
        <div v-if="props.data.cabins" class="flex flex-col justify-center items-center">
          <p>Cabins</p>
          <h1 class="!text-base @sm:!text-2xl font-bold">
            <SingleLineTextEditor :is-editable="false" :value="props.data.cabins" />
          </h1>
        </div>
        <div v-if="props.data.builder" class="flex flex-col justify-center items-center">
          <p>Builder</p>
          <h1 class="!text-base @sm:!text-2xl font-bold">
            <SingleLineTextEditor :is-editable="false" :value="props.data.builder" />
          </h1>
        </div>
      </dl>
    </div>

    <!-- Vessel Gallery -->
    <div class="relative -mt-10">
      <div class="h-[22.5rem] sm:h-full">
        <AssetViewer
          v-if="props.data.images[0]"
          class="w-full overflow-hidden rounded-2xl col-span-2 object-cover"
          loading-strategy="eager"
          :url="`/media/${props.data.images[0]}`"
        />
      </div>

      <!-- Show all button -->
      <button
        v-if="props.data.images.length"
        type="button"
        class="absolute bottom-8 right-8 sm:bottom-8 sm:right-8 z-10 text-gray-900 bg-white hover:bg-gray-100 border border-gray-900 focus:outline-none font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
        @click="showCarouselModal = true"
      >
        <OutlinePhoto class="inline-block w-4 h-4 stroke-2 mr-2" />
        Show all photos
      </button>
    </div>
  </section>

  <Teleport v-if="showCarouselModal" to="body">
    <ImageCarouselModal :slides="props.data.images" @close="showCarouselModal = false" />
  </Teleport>
</template>
