<script setup lang="ts">
import { Ref, inject, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'

import Spinner from '@/components/Spinner.vue'
import AssetDropOnly from '@/components/asset-uploader/AssetDropOnly.vue'
import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { AuthenticationContext } from '@/iam/types'
import { AllDayCardsSectionEditorData } from '@/sections/allDayCards/AllDayCardsSectionEditor'
import { AllDayCardSegment, AllDayCardsLayout } from '@/sections/allDayCards/Type'
import { LayoutTemplate } from '@/sections/types'
import { linkMedia } from '@/services/MediaService'
import { removeDragCursor } from '@/utils/dragcursor'
import { removeHtmlTagsFromString } from '@/utils/stringManipulations'

type Props = {
  /**
   * The section id
   */
  id: string
  /**
   * The slide uri
   */
  uri: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The day cards section's data
   */
  data: AllDayCardsSectionEditorData
  /**
   * The Day cards layout
   */
  layout: AllDayCardsLayout
  /**
   * The lifecycle hook
   */
  lifecycle: Runnable<EditableLifecycleHooks>
}

const props = defineProps<Props>()
const uploadState: Ref<string | null> = ref(null)
const draggedImage: Ref<string | null> = ref(null)
const dragoverElement: Ref<string | null> = ref(null)
const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<any>): void
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

props.lifecycle({
  onHydrated: async () => {
    const defaultLayoutOptions = { type: 'hero-two-column', options: { showDistances: true } }

    if ((!props.layout.type && !props.layout.options) || !props.layout.type || !props.layout.options) {
      emit('update:layout', {
        sectionId: props.id,
        layout: {
          type: props.layout.type || defaultLayoutOptions.type,
          options: props.layout.options || defaultLayoutOptions.options,
        },
      })
    }

    const images = props.data.segments?.reduce((accumulator: string[], currentValue: AllDayCardSegment) => {
      return accumulator.concat(currentValue.images)
    }, [])

    if (images?.length) {
      await linkMedia({ authenticationContext }, props.proposalUri, images)
    }
  },
} as unknown as EditableLifecycleHooks)

const drag = (event: DragEvent): void => {
  if (draggedImage.value !== null) return
  const target = event.target as HTMLElement
  draggedImage.value = target.id
}

const dragover = (event: DragEvent): void => {
  const target = event.target as HTMLElement
  // dropzoneLabel is the incorrect target, so ignore it
  if (dragoverElement.value === target.id || target.id.includes('dropzoneLabel')) {
    return
  }

  if (target.tagName === 'svg') {
    dragoverElement.value = target.parentElement!.id
  } else {
    dragoverElement.value = target.id
  }
}

const drop = (segmentIndex: number, id?: string): void => {
  removeDragCursor()
  if (id) {
    const element = document.getElementById(id) as HTMLImageElement
    const data: AllDayCardsSectionEditorData = ObjectUtil.deepCopy(props.data)

    const selectedImage = element.dataset.imagePath || element.src
    const url = new URL(selectedImage, window.location.origin)
    const mediaUri = url.pathname.split('/')[2]

    data.segments[segmentIndex].heroImage = mediaUri
    if (!data.segments[segmentIndex].images.includes(mediaUri)) {
      data.segments[segmentIndex].images = [element.dataset.imagePath!, ...data.segments[segmentIndex].images]
    }
    emit('update:value', { sectionId: props.id, data: data })
    draggedImage.value = null
    dragoverElement.value = null
  }
}

const dropStop = (segmentIndex: number, index: number, id?: string): void => {
  removeDragCursor()
  if (id) {
    const element = document.getElementById(id) as HTMLImageElement
    const data: AllDayCardsSectionEditorData = ObjectUtil.deepCopy(props.data)

    const selectedImage = element.dataset.imagePath || element.src
    const url = new URL(selectedImage, window.location.origin)
    const mediaUri = url.pathname.split('/')[2]
    data.segments[segmentIndex].stops[index].heroImage = mediaUri
    if (!data.segments[segmentIndex].images.includes(mediaUri)) {
      data.segments[segmentIndex].images = [element.dataset.imagePath!, ...data.segments[segmentIndex].images]
    }
    emit('update:value', { sectionId: props.id, data: data })
    draggedImage.value = null
    dragoverElement.value = null
  }
}

const updateSegment = (field: 'description' | 'label', segmentIndex: number, value: string): void => {
  const data: AllDayCardsSectionEditorData = ObjectUtil.deepCopy(props.data)
  if (field === 'description') {
    data.segments[segmentIndex].description = value
  }

  if (field === 'label') {
    data.segments[segmentIndex].label = value
  }
  emit('update:value', { sectionId: props.id, data: data })
}

const updateStop = (field: string, segmentIndex: number, index: number, value: string): void => {
  const data: AllDayCardsSectionEditorData = ObjectUtil.deepCopy(props.data)

  switch (field) {
    case 'name':
      data.segments[segmentIndex].stops[index].place.name = value
      break
    case 'distance':
      data.segments[segmentIndex].stops[index].pathFromPrevious!.distance.value =
        Number(removeHtmlTagsFromString(value)) || 0
      break
    case 'unit':
      data.segments[segmentIndex].stops[index].pathFromPrevious!.distance.unit = value
      break
    case 'duration':
      data.segments[segmentIndex].stops[index].pathFromPrevious!.duration = value
      break
    case 'description':
      data.segments[segmentIndex].stops[index].place.description = value
      break
    default:
      break
  }

  emit('update:value', { sectionId: props.id, data: data })
}
</script>
<template>
  <div v-if="props.data && props.data.segments">
    <div v-if="!props.layout.type || props.layout.type === 'hero-two-column'" :id="props.id">
      <!-- Day segments -->
      <div
        class="shadow-xl rounded-lg border border-gray-200 dark:border-gray-600"
        :class="{ 'mt-8 @sm:mt-16': segmentIndex !== 0 }"
        v-for="(segment, segmentIndex) of props.data.segments"
        :key="`${segmentIndex} - ${segment.label}`"
        :id="`${props.id}-segment-${segmentIndex}`"
        data-test="segment"
      >
        <!-- segment label -->
        <div class="relative w-full">
          <div class="z-10 absolute left-5 top-5 bg-black/[0.1] backdrop-blur-xl rounded-lg px-5 py-4">
            <h1 class="text-2xl @sm:text-5xl font-medium hover:cursor-pointer">
              <SingleLineTextEditor
                :value="segment.label"
                placeholder="Enter the day label"
                @update:value="updateSegment('label', segmentIndex, $event)"
                data-test="segment-label"
              />
            </h1>
          </div>
        </div>

        <!-- Hero Image -->
        <AssetDropOnly
          :image-index="0"
          :upload-state="uploadState"
          @file:dropped="($event) => drop(segmentIndex, $event)"
          @image:dragover="dragover"
        >
          <AssetViewerPlaceholder
            class="w-full h-full rounded-t-lg"
            :url="`/media/${replacePathToMediaUris(props.proposalUri, segment.heroImage)[0]}`"
            @drag="drag"
          />
        </AssetDropOnly>

        <div class="p-4 @sm:p-8">
          <!-- Segment Description -->
          <p class="@sm:columns-2">
            <MultiLineTextEditor
              class="gap-10 font-light leading-normal text-justify"
              :value="segment.description"
              placeholder="Enter overview"
              @update:value="updateSegment('description', segmentIndex, $event)"
              data-test="segment-description"
            />
          </p>

          <!-- Stops -->
          <div
            v-for="(stop, $index) in segment.stops"
            class="w-full flex flex-col justify-between mt-4 @sm:mt-8"
            :class="[{ 'mb-4 @sm:mb-8': $index !== segment.stops.length - 1 }]"
            :key="$index"
            :id="`${segment.label}-${stop.place.name}`"
            data-test="segment-stop"
          >
            <div class="justify-center flex flex-col @sm:flex-row gap-y-4 @sm:gap-x-8">
              <!-- Stop Image -->
              <AssetDropOnly
                class="basis-1/2"
                :image-index="0"
                :upload-state="uploadState"
                @file:dropped="($event) => dropStop(segmentIndex, $index, $event)"
                @image:dragover="dragover"
              >
                <div class="h-auto">
                  <AssetViewerPlaceholder
                    class="rounded-lg w-full"
                    :url="`/media/${replacePathToMediaUris(props.proposalUri, stop.heroImage)[0]}`"
                    :width-descriptors="['1280w']"
                  />
                </div>
              </AssetDropOnly>

              <div class="w-full flex flex-col basis-1/2 gap-y-4">
                <div>
                  <h4 class="text-2xl font-bold leading-normal">
                    <SingleLineTextEditor
                      :value="stop.place.name"
                      placeholder="Enter the place name"
                      @update:value="updateStop('name', segmentIndex, $index, $event)"
                    />
                  </h4>
                  <h5
                    v-if="
                      (!props.layout.options ||
                        (props.layout.options && props.layout.options.showDistances === true)) &&
                      !(segmentIndex === 0 && $index === 0)
                    "
                    class="!text-sm leading-5 flex gap-x-1"
                  >
                    <span>Distance from last location:</span>
                    <div class="flex flex-row items-center space-x-1">
                      <SingleLineTextEditor
                        placeholder="Enter distance"
                        :value="stop.pathFromPrevious?.distance?.value?.toString() || ''"
                        @update:value="updateStop('distance', segmentIndex, $index, $event)"
                      />
                      <SingleLineTextEditor
                        placeholder="Enter unit"
                        :value="stop.pathFromPrevious?.distance?.unit || ''"
                        @update:value="updateStop('unit', segmentIndex, $index, $event)"
                      />
                      <div class="flex items-center">
                        <span>(</span>
                        <SingleLineTextEditor
                          placeholder="Enter duration"
                          :value="stop.pathFromPrevious?.duration || ''"
                          @update:value="updateStop('duration', segmentIndex, $index, $event)"
                        />
                        <span>)</span>
                      </div>
                    </div>
                  </h5>
                </div>
                <p class="font-light leading-normal">
                  <MultiLineTextEditor
                    :value="stop.place.description"
                    placeholder="Enter an overview"
                    @update:value="updateStop('description', segmentIndex, $index, $event)"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-else class="h-6" />
  </div>
</template>
