<script setup lang="ts">
import { Ref, computed, ref, watch } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import { ViewMode } from '@ankor-io/common/events/Editor'
import { JsonProposal } from '@ankor-io/common/proposal/Proposal'
import { SlideType } from '@ankor-io/common/proposal/SlideType'

import NavBarModalContent from '@/components/modal-content/NavBarModalContent.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { useModal } from '@/modal/useModal'
import NavTabGroup from '@/sections/navbar/NavTabGroup.vue'
import { NavBarLayout, TabSelection, TabSelections } from '@/sections/navbar/types'

type Props = {
  proposalUri: string
  layout: NavBarLayout
  document: JsonProposal
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'change:slide', value: { slideUri: string; viewMode: ViewMode }): void
}>()

const { isOpen, updateModalState } = useModal()
const isItineraryModalOpen: Ref<boolean> = ref(false)
const isVesselModalOpen: Ref<boolean> = ref(false)
const selectedTab: Ref<TabSelection | null> = ref(null)

const numSlides = computed(() => props.document.document.slides.length || 0)
const itinerarySlides = computed(() =>
  props.document.document.slides.filter((item) => item.type === SlideType.ITINERARY),
)
const vesselSlides = computed(() => props.document.document.slides.filter((item) => item.type === SlideType.VESSEL))

const proposalItems = computed(() => [...props.document.yachts, ...props.document.itineraries])

/**
 * @param updatedSelection can be 'Vessel / Itinerary'
 * or null in case nothing should be selected
 */
const selectTab = (updatedSelection: TabSelection | null): void => {
  selectedTab.value = updatedSelection

  if ((isVesselModalOpen.value || isItineraryModalOpen.value) && updatedSelection !== null) {
    return
  }

  if (updatedSelection === TabSelections.VESSELS) {
    isItineraryModalOpen.value = false
    isVesselModalOpen.value = true
  } else if (updatedSelection === TabSelections.ITINERARIES) {
    isItineraryModalOpen.value = true
    isVesselModalOpen.value = false
  }
  updateModalState(true)
}

const changeSlide = (value: { slideUri: string; viewMode: ViewMode }) => {
  updateModalState(false)
  emit('change:slide', { slideUri: value.slideUri, viewMode: value.viewMode })
}

const setNavFlexAlignment = (): string => {
  if (props.layout.type === 'default' || props.layout.options === 'center') {
    return 'justify-center'
  }

  if (props.layout.options === 'right') {
    return numSlides.value > 1 ? 'hidden lg:flex justify-end' : 'justify-end'
  }

  // left
  return numSlides.value > 1 ? 'hidden lg:flex justify-start' : 'justify-start'
}

watch(isOpen, (value) => {
  if (!value) {
    isItineraryModalOpen.value = false
    isVesselModalOpen.value = false
    selectedTab.value = null
  }
})
</script>
<template>
  <div>
    <div
      v-if="numSlides > 1 || (props.layout.type === 'logo' && props.document.template.identity?.companyImage)"
      class="flex flex-col border-b border-gray-200 py-4"
    >
      <!-- Center image -->
      <!-- Appears for left and right alignments on mobile -->
      <div
        v-if="props.layout.type === 'logo' && props.document.template.identity?.companyImage"
        class="w-full relative h-16 px-8 flex items-center justify-center"
        :class="
          numSlides <= 1
            ? { hidden: props.layout.options !== 'center' }
            : { 'lg:hidden': props.layout.options !== 'center' }
        "
      >
        <div class="h-full max-w-[17rem] max-h-14">
          <AssetViewer
            class="w-full h-full object-contain"
            loading-strategy="eager"
            :url="`/media/${props.document.template.identity?.companyImage}`"
          />
        </div>
      </div>
      <div
        class="w-full relative h-16 px-8 flex items-center"
        :class="
          props.layout.options === 'right'
            ? numSlides > 1
              ? 'lg:justify-end'
              : 'justify-center sm:justify-end'
            : props.layout.options === 'left' || !props.layout.options
            ? numSlides > 1
              ? 'lg:justify-start'
              : 'justify-center sm:justify-start'
            : { hidden: numSlides <= 1 }
        "
      >
        <!-- Left image (hidden on mobile) -->
        <div
          v-if="
            props.layout.type === 'logo' &&
            (props.layout.options === 'left' || !props.layout.options) &&
            props.document.template.identity?.companyImage
          "
          class="h-full max-w-[17rem] max-h-14"
          :class="numSlides > 1 ? 'hidden lg:flex' : 'flex'"
        >
          <AssetViewer
            class="w-full h-full object-contain"
            loading-strategy="eager"
            :url="`/media/${props.document.template.identity?.companyImage}`"
          />
        </div>

        <!-- Navbar -->
        <div
          v-if="numSlides > 1"
          class="flex justify-center absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
        >
          <NavTabGroup
            :selected-tab="selectedTab"
            :vessels-length="vesselSlides.length"
            :itineraries-length="itinerarySlides.length"
            @select-tab="selectTab"
          />
        </div>

        <!-- Right image (hidden on mobile) -->
        <div
          v-if="
            props.layout.type === 'logo' &&
            props.layout.options === 'right' &&
            props.document.template.identity?.companyImage
          "
          class="h-full max-w-[17rem] max-h-14"
          :class="setNavFlexAlignment()"
        >
          <AssetViewer
            class="w-full h-full object-contain"
            loading-strategy="eager"
            :url="`/media/${props.document.template.identity?.companyImage}`"
          />
        </div>
      </div>
    </div>

    <ModalContentWrapper v-if="isItineraryModalOpen">
      <NavBarModalContent
        title="Routes"
        :itinerarySlides="itinerarySlides"
        :proposalItems="proposalItems"
        @close="updateModalState(false)"
        @change:slide="changeSlide($event)"
      />
    </ModalContentWrapper>

    <ModalContentWrapper v-if="isVesselModalOpen">
      <NavBarModalContent
        title="Yachts"
        :vesselSlides="vesselSlides"
        :proposalItems="proposalItems"
        @close="updateModalState(false)"
        @change:slide="changeSlide($event)"
      />
    </ModalContentWrapper>
  </div>
</template>
