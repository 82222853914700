<script lang="ts" setup>
import { ChangeEvent, State } from '@ankor-io/common/events/Editor'
import { LineArtTitlepin } from '@ankor-io/icons/line_art'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { HeroTitleData } from '@/sections/hero/types/types'

type Props = {
  data: HeroTitleData
  id: string
  state: State
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<any>): void
}>()

const update = (event: { value: HeroTitleData }): void => {
  emit('update:value', { sectionId: props.id, data: event.value })
}
</script>
<template>
  <div
    class="hero-overlay z-10 absolute left-4 right-4 @sm:right-auto top-4 bg-black/[0.1] backdrop-blur-xl rounded-lg px-5 py-4"
  >
    <h1 class="font-medium text-2xl @sm:text-5xl">
      <SingleLineTextEditor
        placeholder="Enter a title"
        :value="props.data.heading"
        @update:value="update({ value: { heading: $event, subheading: props.data.subheading } })"
      />
    </h1>
    <div class="hero-overlay-subheading flex items-center mt-1.5">
      <LineArtTitlepin class="w-4 h-4 inline-block mr-1.5 -ml-1 stroke-theme-accent" />
      <h3 class="font-light text-sm @sm:text-base">
        <SingleLineTextEditor
          placeholder="Enter a sub heading"
          :value="props.data.subheading"
          @update:value="update({ value: { subheading: $event, heading: props.data.heading } })"
        />
      </h3>
    </div>
  </div>
</template>
