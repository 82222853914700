<script lang="ts" setup>
/**
 * ###############################################
 * ################### Input #####################
 * id - Proposal ID
 * uri - URI of the vessel entity
 * data - {@VesselHeroData} - Vessel hero data
 * layout - {@LayoutTemplate} - Layout template
 * ################### Output ####################
 * Renders vessel hero section (non-editable)
 * ###############################################
 */
import { Ref, ref } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import ImageCarouselModal from '@ankor-io/blocks/components/ImageCarouselModal/ImageCarouselModal.vue'
import { LifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { Vessel } from '@ankor-io/common/vessel/types'
import { OutlinePhoto } from '@ankor-io/icons/outline'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'
import { VesselHeroData } from '@/sections/vessel/hero/types'

export type Props = {
  id: string
  proposalUri: string
  data: VesselHeroData
  layout: LayoutTemplate
  lifecycle: Runnable<LifecycleHooks>
  source?: { uri: string; [key: string]: any }
}

const props = defineProps<Props>()

props.lifecycle({
  onBeforeAttach() {
    // if the source is provided and the data is null, use that to hydrate. This is necessary for the lite editor
    // to work in memory
    if (props.source && !props.data) {
      //@ts-ignore this works because of the lifecycle bound to the section class
      this.setData(this.dataFrom<Vessel>(props.source))
    }
  },
} as LifecycleHooks)

const showCarouselModal: Ref<boolean> = ref(false)
</script>
<template>
  <section class="antialiased px-4 @sm:p-8 mx-auto text-center w-full" v-if="props.data">
    <!-- Vessel name -->
    <h1 class="text-2xl @sm:text-3xl font-bold mb-4">
      <SingleLineTextEditor :is-editable="false" :value="props.data.name" placeholder="Vessel name" />
    </h1>

    <!-- Vessel type -->
    <h5 class="text-xl">
      <SingleLineTextEditor :is-editable="false" :value="props.data.type" placeholder="Vessel Type" />
    </h5>

    <div class="flex justify-center mt-4">
      <hr class="border-t-2 my-1 border-theme-primary w-28" />
    </div>

    <!-- Featured Items -->
    <div class="relative z-10 mt-8 select-none">
      <dl
        class="inline-flex flex-wrap bg-white justify-center p-4 shadow-lg gap-2 @sm:gap-8 rounded-lg border border-gray-200 dark:border-gray-600"
      >
        <div v-if="props.data.length" class="flex flex-col justify-center items-center">
          <p>Length</p>
          <h1 class="!text-base @sm:!text-2xl font-bold">
            <SingleLineTextEditor :is-editable="false" :value="props.data.length" />
          </h1>
        </div>
        <div v-if="props.data.sleeps" class="flex flex-col justify-center items-center">
          <p>Sleeps</p>
          <h1 class="!text-base @sm:!text-2xl font-bold">
            <SingleLineTextEditor :is-editable="false" :value="props.data.sleeps" />
          </h1>
        </div>
        <div v-if="props.data.cabins" class="flex flex-col justify-center items-center">
          <p>Cabins</p>
          <h1 class="!text-base @sm:!text-2xl font-bold">
            <SingleLineTextEditor :is-editable="false" :value="props.data.cabins" />
          </h1>
        </div>
        <div v-if="props.data.builder" class="flex flex-col justify-center items-center">
          <p>Builder</p>
          <h1 class="!text-base @sm:!text-2xl font-bold">
            <SingleLineTextEditor :is-editable="false" :value="props.data.builder" />
          </h1>
        </div>
      </dl>
    </div>

    <!-- Vessel Gallery -->
    <div class="relative -mt-6">
      <div class="w-full h-[22.5rem] sm:h-full col-span-2">
        <AssetViewer
          v-if="props.data.images?.[0]"
          class="overflow-hidden rounded-2xl object-cover"
          :url="`/media/${props.data.images[0]}`"
        />
      </div>

      <!-- Show all button -->
      <button
        v-if="props.data.images?.length"
        type="button"
        class="absolute bottom-8 right-8 sm:bottom-8 sm:right-8 z-10 text-gray-900 bg-white hover:bg-gray-100 border border-gray-900 focus:outline-none font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
        @click="showCarouselModal = true"
      >
        <OutlinePhoto class="inline-block w-4 h-4 stroke-2 mr-2" />
        Show all photos
      </button>
    </div>

    <Teleport v-if="showCarouselModal" to="body">
      <ImageCarouselModal :slides="props.data.images" @close="showCarouselModal = false" />
    </Teleport>
  </section>
</template>
