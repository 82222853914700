import { GeoJSON } from 'geojson'

import { AlgoliaCoordinates } from '../itinerary/PlaceIndexItem'

//
// this is the doc for the public facing API
// Note: not all fields are exposed on the 'WireVessel'
//

/**
 * @openapi
 * components:
 *   schemas:
 *     Vessel:
 *       type: object
 *       properties:
 *         yachtType:
 *           type: array
 *           description: The vessel types
 *           example:
 *             - Sailing
 *             - Catamaran
 *           items:
 *             type: string
 *             enum:
 *               - Gulet
 *               - Sailing
 *               - Catamaran
 *               - Motor
 *               - Power Catamaran
 *               - Classic
 *               - Expedition
 *               - Sport fishing
 *         uri:
 *           type: string
 *           description: The uri of the vessel
 *           example: c::9813498354::vessel::24166ef0-8ffa-11ee-8457-0358ef6f80eb
 *         description:
 *           type: string
 *           description: The description of the vessel
 *           example: A beautiful vessel for a great vacation
 *         additionalInfo:
 *           description: |
 *             Key value pair of additional information for the vessel, previous names, class, etc.
 *             > A pair of name to information
 *             >    - label: Artwork
 *             >    - value: Monet
 *           type: array
 *           items:
 *             $ref: '#/components/schemas/KVPair'
 *         attachments:
 *           description: |
 *             Attached files to the vessel, e.g. plans, brochures;
 *             > A pair of the friendly name to relative url of the file
 *             >    - label: Spring Menu
 *             >    - value: attachments/spring-menu.pdf
 *           type: array
 *           items:
 *             $ref: '#/components/schemas/KVPair'
 *         links:
 *           description: |
 *             Links to external files
 *             > A pair of the friendly name to url targets
 *             >    - label: Reviews
 *             >    - value: https://domain.tld/reviews/
 *           type: array
 *           items:
 *             $ref: '#/components/schemas/KVPair'
 *         seasons:
 *           description: The time periods of the vessel seasons
 *           type: array
 *           items:
 *             $ref: '#/components/schemas/Season'
 *         blueprint:
 *           $ref: '#/components/schemas/VesselBlueprint'
 *         crew:
 *           description: Crew Profiles
 *           type: array
 *           items:
 *             $ref: '#/components/schemas/CrewMember'
 *         contact:
 *           description: Contact Information
 *           type: array
 *           items:
 *             $ref: '#/components/schemas/Person'
 *         variants:
 *           description: The variants of the vessel that can be made available
 *           type: array
 *           items:
 *             $ref: '#/components/schemas/Variants'
 *         visibility:
 *           type: string
 *           enum:
 *             - PRIVATE
 *             - PUBLIC
 *
 */

/**
 *
 * @openapi
 * components:
 *   schemas:
 *     VesselBlueprint:
 *       type: object
 *       required: [name]
 *       properties:
 *         name:
 *           description: The name of the boat
 *           type: string
 *           example: Northern Star
 *         mmsi:
 *           description: The MMSI number of the boat
 *           type: string
 *           example: 123456789
 *         mainsailTypes:
 *           description: Furling, In Mast, Basket, Batten etc.
 *           type: array
 *           example:
 *            - Furling
 *            - In Mast
 *           items:
 *             type: string
 *         genoaTypes:
 *           description: The genoa type of the boat, furling, self tacking, etc.
 *           type: array
 *           example:
 *            - Self Tacking
 *           items:
 *             type: string
 *         hero:
 *           description: The primary image of the boat
 *           type: string
 *           example: /media/media::image::c::9813498354::vessel::24166ef0-8ffa-11ee-8457-0358ef6f80eb::assets::ed978cd0-942f-11ee-aa22-d34f32f38516.png/{imageVariant}
 *         images:
 *           description: "Image ends with a variant {imageVariant}. Replace with any one of the supported variants while fetching the image. \n\n\t Blur image: \n\t * blur \n\n\t Contain/fit to width: \n\t * 108w \n\t * 320w \n\t * 640w \n\t * 960w \n\t * 1280w \n\t * 2560w \n\n\t Cover by width: \n\t * 2560x \n\t * 1280x \n\t * 960x \n\t * 720x \n\t * 640x \n\t * 320x \n\t * 160x \n\n\t Cover by height: \n\t * x2560 \n\t * x1280 \n\t * x960 \n\t * x720 \n\t * x640 \n\t * x320 \n\t * x160 \n\n\t"
 *           type: array
 *           example:
 *             - /media/media::image::c::9813498354::vessel::24166ef0-8ffa-11ee-8457-0358ef6f80eb::assets::ed978cd0-942f-11ee-aa22-d34f32f38516.png/{imageVariant}
 *           items:
 *             type: string
 *         length:
 *           description: Length of the boat in meters
 *           type: number
 *           example: 23
 *         flag:
 *           description: The flag of the boat represents its country of registry
 *           type: string
 *           example: British Virgin Islands
 *         registryPort:
 *           description: Port of registry for the boat
 *           type: string
 *           example: Road Town
 *         cabins:
 *           description: The number of bedrooms/cabins in the boat
 *           type: number
 *           example: 3
 *         sleeps:
 *           description: The number of people the boat can accommodate for sleeping
 *           type: number
 *           example: 6
 *         cruisingCapacity:
 *           description: The number of people the boat can accommodate for while cruising
 *           type: number
 *           example: 12
 *         staticCapacity:
 *           description: The number of people the boat can accommodate when static (anchored)
 *           type: number
 *           example: 12
 *         make:
 *           description: The builder of the boat model e.g., Sunseeker, Lurssen, etc.
 *           type: string
 *           example: Infinity
 *         model:
 *           description: Super yachts don't have a model since they are bespoke, however boats do
 *           type: string
 *           example: Beyond
 *         bathrooms:
 *           description: Bathrooms
 *           type: number
 *           example: 3
 *         builtYear:
 *           description: The year the boat was built/manufactured
 *           type: number
 *           example: 2019
 *         decks:
 *           description: Number of decks
 *           type: number
 *           example: 2
 *         architect:
 *           description: The architect of the boat
 *           type: string
 *           example: Greg Denham
 *         interiorDesigner:
 *           description: The interior designer of the boat
 *           type: string
 *           example: Kelly Hoppen
 *         maxCrew:
 *           description: Max crew members
 *           type: number
 *           example: 8
 *         beam:
 *           description: The width of the boat at its widest point in meters
 *           type: number
 *           example: 6
 *         draft:
 *           description: The minimum depth of water a boat needs to float in meters
 *           type: number
 *           example: 2
 *         hullType:
 *           description: The type of the hull of the boat
 *           type: string
 *           example: Monohull
 *         hullConstruction:
 *           description: The material of the hull of the boat
 *           type: string
 *           example: Steel
 *         superStructure:
 *           description: The material of the superstructure of the boat
 *           type: array
 *           example:
 *             - Steel
 *             - Alloy
 *           items:
 *             type: string
 *         tonnage:
 *           description: The boat's gross tonnage
 *           type: number
 *           example: 200
 *         engines:
 *           description: The description of the boat's engines models/specs
 *           type: string
 *           example: 2 x 1000hp
 *         fuelCapacity:
 *           description: The fuel capacity of the boat in liters
 *           type: number
 *           example: 10000
 *         fuelPerHour:
 *           description: Fuel consumption of the boat per hour in liters
 *           type: number
 *           example: 100
 *         topSpeed:
 *           description: The top speed of the boat in knots
 *           type: number
 *           example: 20
 *         cruiseSpeed:
 *           description: The cruising speed of the boat in knots
 *           type: number
 *           example: 15
 *         stabilizers:
 *           description: Stabilizers of the boat
 *           type: boolean
 *           example: true
 *         refitYear:
 *           description: Year the boat was last refitted
 *           type: number
 *           example: 2021
 *         cabinLayout:
 *           description: Cabin configuration of the boat
 *           type: array
 *           items:
 *             $ref: '#/components/schemas/CabinLayout'
 *         amenities:
 *           description: Amenities available on the boat
 *           type: array
 *           items:
 *             $ref: '#/components/schemas/Amenity'
 *         entertainment:
 *           description: Entertainment options on the boat
 *           type: array
 *           example: Cinema
 *           items:
 *             type: string
 *         toys:
 *           description: Toys on the boat
 *           type: array
 *           example: Paddleboard
 *           items:
 *             $ref: '#/components/schemas/Toy'
 *         tenders:
 *           description: Tenders (small boats) available on the boat
 *           type: array
 *           example: Jet Ski
 *           items:
 *             type: string
 *
 *     ###
 *     KVPair:
 *       type: object
 *       properties:
 *         label:
 *           type: string
 *           description: A key, name or label
 *           example: A label
 *         value:
 *           type: string
 *           description: The value for that label
 *           example: A value
 *
 *     ###
 *     Toy:
 *       type: object
 *       properties:
 *         label:
 *           type: string
 *           description: A key, name or label
 *           example: Surfboard
 *         quantity:
 *           type: number
 *           description: How many of the Toy
 *           example: 3
 *
 *     ###
 *     CabinLayout:
 *       description: |
 *         Cabin configuration of the boat
 *         > label: Double Cabin
 *         > quantity: 3
 *       type: object
 *       properties:
 *         label:
 *           type: string
 *           description: A key, name or label for cabin type
 *           example: Double Cabin
 *         quantity:
 *           type: string
 *           description: Number or cabins of that type
 *           example: 1/2
 *
 *     ###
 *     Amenity:
 *       type: object
 *       properties:
 *         label:
 *           type: string
 *           description: A key, name or label
 *           example: Jacuzzi
 *         quantity:
 *           type: number
 *           description: How many of that amenity
 *           example: 1
 *
 *     ###
 *     Season:
 *       type: object
 *       properties:
 *         label:
 *           type: string
 *           description: The name of the season
 *           example: High
 *         description:
 *           type: string
 *           description: A description of the season
 *           example: In the East Med
 *         intervals:
 *           type: array
 *           description: The ISO-8601 time intervals this season should be applied
 *           example:
 *             - 2023-07-01T00:00:00.000Z/2023-09-30T23:59:59.999Z
 *             - 2023-10-01T00:00:00.000Z/2023-12-31T23:59:59.999Z
 *           items:
 *             $ref: '#/components/schemas/ISO8601_Interval'
 *
 *     ###
 *     ISO8601_Interval:
 *       type: string
 *       example: 2023-07-01T00:00:00.000Z/2023-09-30T23:59:59.999Z
 *
 *     ###
 *     CrewMember:
 *       description: Profile of a crew member
 *       type: object
 *       required: [name]
 *       properties:
 *         name:
 *           description: The name of the crew member
 *           type: string
 *           example: John Doe
 *         avatar:
 *           description: The avatar of the crew member
 *           type: string
 *           example: /media/media::image::c::9813498354::vessel::24166ef0-8ffa-11ee-8457-0358ef6f80eb::assets::ed978cd0-942f-11ee-aa22-d34f32f38516.png/{imageVariant}
 *         bio:
 *           description: The bio of the crew member
 *           type: string
 *           example: John is a seasoned sailor with 20 years of experience
 *         role:
 *           description: The role of the crew member
 *           type: array
 *           items:
 *             type: string
 *             example: Captain
 *
 *     ###
 *     Person:
 *       description: A Person
 *       required: [name]
 *       type: object
 *       properties:
 *         name:
 *           description: The name of the person
 *           type: string
 *           example: John Doe
 *         companyName:
 *           description: The company the person works for
 *           type: string
 *           example: Charter Company
 *         email:
 *           description: The email of the person
 *           type: string
 *           example: john@charter-company.com
 *         website:
 *           description: The website of the company the person works for
 *           type: string
 *           example: https://www.charter-company.com
 *         country:
 *           description: The country where the person is located
 *           type: string
 *           example: Canada
 *         phoneNumbers:
 *           description: The phone numbers for the person
 *           type: array
 *           items:
 *             type: string
 *             example: +1 123 456 7890
 *         mobileNumber:
 *           description: The mobile number for the person
 *           type: string
 *           example: +1 123 456 7890
 *
 *     ###
 *     Variants:
 *       description: A variant of the vessel
 *       type: object
 *       properties:
 *         id:
 *           description: The id of the variant
 *           type: string
 *           example: 24166ef0-8ffa-11ee-8457-0358ef6f80eb
 *         label:
 *           description: The name of the variant
 *           type: string
 *           example: High Season July-August
 *         description:
 *           description: A description of the variant
 *           type: string
 *           example: High season in the East Med
 *         maxPassengers:
 *           description: The maximum number of passengers for this variant
 *           type: number
 *           example: 12
 *         active:
 *           description: Is this variant active
 *           type: boolean
 *           example: true
 *         petsAllowed:
 *           description: Are pets allowed on this variant
 *           type: boolean
 *           example: false
 *         turnaround:
 *           description: The turnaround duration for this variant in ISO 8601 duration format
 *           type: string
 *           example: PT1H
 *         pricing:
 *           description: The pricing for this variant
 *           type: object
 *           properties:
 *             currency:
 *               description: The currency of the pricing
 *               type: string
 *               example: USD
 *             lineItems:
 *               description: The line items for the pricing
 *               type: array
 *               items:
 *                 type: object
 *                 properties:
 *                   item:
 *                     description: The item being priced
 *                     type: string
 *                     example: Charter Fee
 *                   conditions:
 *                     description: The conditions for the pricing
 *                     type: string
 *                     example: Per Person
 *                   quantity:
 *                     description: The quantity of the item
 *                     type: number
 *                     example: 1
 *                   unitPrice:
 *                     description: The unit price of the item
 *                     type: number
 *                     example: 10000
 *                   amount:
 *                     description: The total amount for the item
 *                     type: number
 *                     example: 10000
 *                   taxRate:
 *                     description: The tax rate for the item
 *                     type: object
 *                     properties:
 *                       label:
 *                         description: The label for the tax rate
 *                         type: string
 *                         example: VAT
 *                       value:
 *                         description: The value of the tax rate
 *                         type: number
 *                         example: 0.2
 *                   discount:
 *                     description: The discount for the item
 *                     type: object
 *                     properties:
 *                       type:
 *                         description: The type of discount
 *                         type: string
 *                         example: Early Bird
 *                       value:
 *                         description: The value of the discount
 *                         type: string
 *                         example: 1000
 *             subTotal:
 *               description: The subtotal for the pricing
 *               type: number
 *               example: 10000
 *             totalTax:
 *               description: The total tax for the pricing
 *               type: number
 *               example: 2000
 *             total:
 *               description: The total for the pricing
 *               type: number
 *               example: 12000
 *             note:
 *               description: A note for the pricing
 *               type: string
 *               example: Prices are subject to change
 *             inputAmountTaxed:
 *               description: The input amount taxed
 *               type: string
 *               enum:
 *                 - INCLUSIVE
 *                 - EXCLUSIVE
 *                 - NONE
 *             charterFee:
 *               description: The charter fee
 *               type: number
 *               example: 10000
 *             unit:
 *               description: The unit of the pricing
 *               type: string
 *               enum:
 *                 - WEEK
 *                 - DAY
 *                 - HOUR
 *         intervals:
 *           description: The ISO-8601 time intervals this variant should be applied
 *           type: array
 *           items:
 *             type: string
 *             example: 2023-11-01T00:00:00.000Z/2024-02-29T23:59:59.999Z
 *         geoFilters:
 *           description: The geo filters for this variant
 *           type: array
 *           items:
 *             type: object
 *             properties:
 *               label:
 *                 description: The label for the geo filter
 *                 type: string
 *                 example: Greece
 *               uri:
 *                 description: The uri for the geo filter
 *                 type: string
 *                 example: a::geo::fad7e600-7cfc-11ee-8d1d-cd451efe3be5
 *               operation:
 *                 description: The operation for the geo filter
 *                 type: string
 *                 enum:
 *                   - union
 *                   - intersection
 *         isUsingDefaultToysAmenities:
 *           description: Is this variant using the default toys and amenities (defaults to false)
 *           type: boolean
 *           example: true
 *         amenities:
 *           description: Amenities available on the boat
 *           type: array
 *           items:
 *             $ref: '#/components/schemas/Amenity'
 *         entertainment:
 *           description: Entertainment options on the boat
 *           type: array
 *           example: Cinema
 *           items:
 *             type: string
 *         toys:
 *           description: Toys on the boat
 *           type: array
 *           example: Paddleboard
 *           items:
 *             $ref: '#/components/schemas/Toy'
 *         tenders:
 *           description: Tenders (small boats) available on the boat
 *           type: array
 *           example: Jet Ski
 *           items:
 *             type: string
 *
 */

/**
 *
 * @openapi
 * components:
 *   schemas:
 *     CalendarEvent:
 *       type: object
 *       required: [name]
 *       properties:
 *         id:
 *           description: The id of the event
 *           type: string
 *           example: 2da8a880-e200-11ef-93c3-3978caebe63d
 *         title:
 *           description: The title of the calendar event
 *           type: string
 *           example: Charter for John Doe
 *         eventType:
 *           description: The type of the event
 *           type: string
 *           enum:
 *             - Booked
 *             - Tentative/Option
 *             - Maintenance
 *             - In transit
 *             - Flexible use
 *             - Boat show
 *             - Other
 *         reference:
 *           description: A customer field to store their reference id, booking number, or similar
 *           type: string
 *           example: CHARTER-123
 *         interval:
 *           description: The start and end dateTime of the event in ISO-8601 format split by '/'
 *           type: string
 *           example: 2025-02-01T01:30:00.000+11:00/2025-03-06T02:30:00.000+08:00
 *         turnaroundDuration:
 *           description: The turnaround duration of the event in ISO 8601 duration format, i.e. how much padding is needed before the next event
 *           type: string
 *           example: PT45H
 *         allDay:
 *           description: Is this event technically an all day event (used for UI rendering)
 *           type: boolean
 *           example: false
 *         embark:
 *           $ref: '#/components/schemas/Place'
 *         disembark:
 *           $ref: '#/components/schemas/Place'
 *         notes:
 *           description: Any notes for the event.
 *           type: string
 *           example: Please bring your own snorkel gear
 *         variantLabel:
 *           description: The label of the variant
 *           type: string
 *           example: High Season July-August
 *         showTitle:
 *           description: Should the title be hidden?
 *           type: boolean
 *           example: false
 *
 */

/**
 *
 * @openapi
 * components:
 *   schemas:
 *     CalendarEventCreateFields:
 *       type: object
 *       required: [name]
 *       properties:
 *         title:
 *           description: The title of the calendar event
 *           type: string
 *           example: Charter for John Doe
 *         reference:
 *           description: A customer field to store their reference id, booking number, or similar
 *           type: string
 *           example: CHARTER-123
 *         interval:
 *           description: The start and end dateTime of the event in ISO-8601 format split by '/'
 *           type: string
 *           example: 2025-02-01T01:30:00.000+11:00/2025-03-06T02:30:00.000+08:00
 *         turnaroundDuration:
 *           description: The turnaround duration of the event in ISO 8601 duration format, i.e. how much padding is needed before the next event
 *           type: string
 *           example: PT45H
 *         allDay:
 *           description: Is this event technically an all day event (used for UI rendering)
 *           type: boolean
 *           example: false
 *         eventType:
 *           description: The type of the event
 *           type: string
 *           enum:
 *             - Booked
 *             - Tentative/Option
 *             - Maintenance
 *             - In transit
 *             - Flexible use
 *             - Boat show
 *             - Other
 *         embark:
 *           $ref: '#/components/schemas/Place'
 *         disembark:
 *           $ref: '#/components/schemas/Place'
 *         notes:
 *           description: Any notes for the event.
 *           type: string
 *
 */

/**
 * Main class representing a vessel.
 * A vessel is a transport medium for people. It is currently used to represent yachts and boats, however it can be used to represent other types of transport mediums such as cars, jets, etc.
 */
export declare type Vessel = {
  /**
   * Used a facet for filtering
   * Super Yacht, Luxury Yacht, Motor Yacht, Sail Yacht, Mega Yacht, Sail boat, Catamaran, Tender, Boat etc.
   * @deprecated - Will be deprecated in favor of yachtType
   */
  type?: string[]
  /**
   * The type of vessel that Ex. Gulet, Sailing, Catamaran, Motor, Expedition, Classic, Sport fishing
   */
  yachtType?: string[]
  /**
   * The option for chartering the vessel. Ex:
   * Bareboat - comes without a skipper or any crew
   * Skippered - comes with a skipper
   * Crewed - comes with a skipper and a crew, who as well as helping with navigation and sailing also prepare meals and drinks for you
   */
  charterType?: string[]
  /**
   * The category of vessel based on length. Mass market (<10m), Luxury yacht (10-24m), Superyacht (24-70m), and Megayacht (>70m)
   */
  category?: CategoryTypes
  /**
   * The URI of the vessel
   */
  uri: string
  /**
   * Default description of the vessel
   */
  description: string
  /**
   * Additional information about the vessel
   * Former name, boat/yacht class, etc.
   * This is a list of key value pairs that the platform doesn't necessarily care about but is important for the vessel creator / owner
   */
  additionalInfo?: KVPair[]
  /**
   * The list of attachments for a vessel
   */
  attachments?: KVPair[]
  /**
   * The list of links for a vessel
   */
  links?: KVPair[]
  /**
   * Season and duration of the season
   */
  seasons?: Season[]
  /**
   * The calendar of the vessel
   */
  calendarEvents?: CalendarEvent[]
  /**
   * The blueprint of the vessel (e.g., BoatBlueprint, CarBlueprint, JetBlueprint, etc.)
   */
  blueprint: Blueprint
  /**
   * The crew members on the vessel
   */
  crew?: CrewMember[]
  /**
   * The contact information for the vessel
   */
  contacts?: Person[]
  /**
   * The variants of the vessel
   */
  variants: Variant[]
  /**
   * The tags of the vessel
   */
  tags: string[]
  /**
   * Shows that the vessel has/is participating in
   * Ex: ICE 2023, FLIBS 2023, etc.
   */
  shows?: string[]
  /**
   * Describes the vessel visibility status
   */
  visibility?: VesselVisibility
  /**
   * A number, 0 or 1 to determine if a vessel result is boosted in search results
   * A number makes it extendable to go beyond 0 and 1 if search results could be ranked more specifically
   */
  boosted?: number
  /**
   * Whether a vessel is premium listed or not
   */
  isPremiumListed?: boolean
}

/**
 * Defines the category of yacht
 */
export type CategoryTypes = 'Mass market' | 'Luxury yacht' | 'Superyacht' | 'Megayacht'

export enum VesselVisibility {
  /**
   * The vessel is visible to the public
   */
  PUBLIC = 'PUBLIC',
  /**
   * The vessel is visible only to the owner
   */
  PRIVATE = 'PRIVATE',
}

/**
 * Defines a person
 */
export type Person = {
  /**
   * The name of the person
   */
  name: string
  /**
   * The company the person works for
   */
  companyName: string
  /**
   * The email of the person
   */
  email?: string
  /**
   * The website of the company the person works for
   */
  website?: string
  /**
   * The country where the person is located
   */
  country?: string
  /**
   * The phone numbers of the person
   */
  phoneNumbers?: string[]
  /**
   * The mobile number of the person
   */
  mobileNumber?: string
}

/**
 * Defines a key value pair
 */
export type KVPair = {
  label: string
  value: string
}

/**
 * Defines a Boat Blueprint
 */
export type Blueprint = {
  /**
   *The name of the boat
   */
  name: string
  /**
   * The MMSI number of the boat
   */
  mmsi?: string
  /**
   * Furling, In Mast, Basket, Batten etc.
   */
  mainsailTypes?: string[]
  /**
   * The genoa type of the boat, furling, self tacking, etc.
   */
  genoaTypes?: string[]
  /**
   * The primary image of the boat
   */
  hero?: string
  /**
   * Array of images paths
   */
  images?: string[]
  /**
   Length of the boat in meters
   */
  length: number
  /**
   * The flag of the boat represents its country of registry
   */
  flag: string
  /**
   * Port of registry for the boat
   */
  registryPort?: string
  /**
   *   The number of bedrooms/cabins in the boat
   */
  cabins?: number
  /**
   *   The number of people the boat can accommodate for sleeping
   */
  sleeps?: number
  /**
   * The number of people the boat can accommodate for while cruising
   */
  cruisingCapacity?: number
  /**
   * The number of people the boat can accommodate when static (anchored)
   */
  staticCapacity?: number
  /**
   *   The builder of the boat model e.g., Sunseeker, Lurssen, etc.
   */
  make?: string
  /**
   * Super yachts don't have a model since they are bespoke, however boats do
   */
  model?: string
  /**
   * Bathrooms
   */
  bathrooms?: number
  /**
   *  The year the boat was built/manufactured
   */
  builtYear?: number
  /**
   * Number of decks
   */
  decks?: number
  /**
   *   The architect of the boat
   */
  architect?: string
  /**
   *   The interior designer of the boat
   */
  interiorDesigner?: string
  /**
   * Max crew members
   */
  maxCrew?: number
  /**
   *   The width of the boat at its widest point in meters
   */
  beam?: number
  /**
   * The minimum depth of water a boat needs to float in meters
   */
  draft?: number
  /**
   *   The type of the hull of the boat
   */
  hullType?: string
  /**
   * The material of the hull of the boat
   */
  hullConstruction?: string
  /**
   * The material of the superstructure of the boat
   */
  superStructure?: string[]
  /**
   *  The boat's gross tonnage
   */
  tonnage?: number
  /**
   *  The description of the boat's engines models/specs
   */
  engines?: string
  /**
   *   The fuel capacity of the boat in liters
   */
  fuelCapacity?: number
  /**
   *   Fuel consumption of the boat per hour in liters
   */
  fuelPerHour?: number
  /**
   *   The top speed of the boat in knots
   */
  topSpeed?: number
  /**
   *   The cruising speed of the boat in knots
   */
  cruiseSpeed?: number
  /**
   * Stabilizers of the boat
   */
  stabilizers?: boolean
  /**
   * Year the boat was last refitted
   */
  refitYear?: number
  /**
   * Cabin configuration of the boat
   */
  cabinLayout?: CabinLayout[]
  /**
   * Default amenities available on the boat
   */
  amenities?: Amenity[]
  /**
   * Default entertainment options on the boat
   */
  entertainment?: string[]
  /**
   * Default toys on the boat
   */
  toys?: Toy[]
  /**
   * Default tenders (small boats) available on the boat
   */
  tenders?: string[]
}

/**
 * Defines the cabin layout of a boat
 */
export type CabinLayout = {
  /**
   * Label is the type of the cabin
   */
  label: string
  /**
   * Value is the generally the quantity of the cabin label
   */
  value: string
}

export type Amenity = {
  /**
   * Name of the amenity on the vessel
   */
  label: string
  /**
   * Quantity of the amenity on the vessel
   */
  quantity?: number
}

export type Toy = {
  /**
   * Name of the toy on the vessel
   */
  label: string
  /**
   * Quantity toy on the vessel
   */
  quantity?: number
}

/**
 * Defines a member of the vessel crew
 */
export type CrewMember = {
  /**
   * The name of the crew member
   */
  name: string
  /**
   * The avatar of the crew member
   */
  avatar?: string
  /**
   * The bio of the crew member
   */
  bio?: string
  /**
   * The role of the crew member
   * @deprecated - Will be deprecated in favor of roles
   */
  role?: string
  /**
   * The roles of the crew member
   */
  roles?: string[]
  /**
   * The email of the crew member
   */
  email?: string
  /**
   * The phone number of the crew member
   */
  phone?: string
}

/**
 * Defines a variant of a vessel
 */
export type Variant = {
  /**
   * Time based uuid of the variant
   */
  id?: string
  /**
   * The label of the variant e.g., Term, Day
   */
  label: string
  /**
   * The geographic areas the variant includes / excludes
   */
  geoFilters?: GeoFilter[]
  /**
   * Whether the variant is active
   */
  active?: boolean
  /**
   * The intervals for when the variant is available
   * Interval string is '/' separated
   * 1. R(n) - Recurring (n)times - Optional
   * 2. P1Y - Period 1 Year (ISO-8601) - Optional
   * 3. --01-01T13:00:00Z / 2022-01-01T13:00:00Z / S1697091538 - Start Date (ISO-8601) - Required
   * 4. --01-01T13:00:00Z / 2022-01-01T13:00:00Z / S1697091538 - End Date (ISO-8601) - Required
   * Examples:
   * R2/P1Y/--01-01T13:00:00Z/--03-31T23:59:59Z
   * R/P1Y/2022-01-01T13:00:00Z/2022-03-31T23:59:59Z
   * R/P1Y/S1697091538/S1997091538
   * --01-01T13:00:00Z/--03-31T23:59:59Z
   * 2022-01-01T13:00:00Z/2022-03-31T23:59:59Z
   * S1697091538/S1997091538
   * Preference is to store as epoch time for start and end date
   */
  intervals?: string[]
  /**
   * A ISO-8601 string representation for a duration of time,
   * describing the total duration of default turnaround
   */
  turnaround?: string
  /**
   * Description of the variant
   */
  description?: string
  /**
   * Pricing details for the variant
   */
  pricing?: Pricing
  /**
   * The maximum number of passengers
   */
  maxPassengers?: number
  /**
   * A toggle to specify if the variant is inheriting the blueprint data for toys, amenities, entertainment and tenders
   * Default is false
   */
  isUsingDefaultToysAmenities?: boolean
  /**
   * Array of amenities available on the vessel
   */
  amenities?: Amenity[]
  /**
   * Array of entertainment options on the vessel
   */
  entertainment?: string[]
  /**
   * Array of toys on the vessel
   */
  toys?: Toy[]
  /**
   * Array of tenders (small boats) available on the vessel
   */
  tenders?: string[]
  /**
   * Whether pets are allowed on the vessel
   */
  petsAllowed?: boolean
}

export type Pricing = {
  /**
   * The currency of the pricing ISO-4217
   */
  currency: string
  /**
   * General terms, conditions, and notes for the pricing
   */
  note?: string
  /**
   * The price unit for the pricing
   */
  unit: Unit
  /**
   * Whether the items included are tax inclusive or exclusive
   */
  inputAmountTaxed: InputAmountsTaxed
  /**
   * The line items for the pricing
   */
  lineItems: LineItem[]
  /**
   * The charter fee for the variant
   */
  charterFee?: number
  /**
   * subtotal of the pricing based on the inputAmountTaxed
   */
  subTotal?: number
  /**
   * The total tax of all items
   */
  totalTax?: number
  /**
   * The total price (damages) of all items including tax
   */
  total?: number
}

/**
 * Defines the unit for pricing
 */
export type Unit = 'WEEK' | 'DAY' | 'HOUR'

/**
 * Defines the enum tax values
 */
export enum InputAmountsTaxedValues {
  INCLUSIVE = 'INCLUSIVE',
  EXCLUSIVE = 'EXCLUSIVE',
  NONE = 'NONE',
}

/**
 * Defines the amount type for pricing
 */
export type InputAmountsTaxed =
  | InputAmountsTaxedValues.INCLUSIVE
  | InputAmountsTaxedValues.EXCLUSIVE
  | InputAmountsTaxedValues.NONE

/**
 * Defines a line item for pricing
 */
export type LineItem = {
  /**
   * The name of the line item
   */
  item: string
  /**
   * The conditions are visible to the RA and not to customer
   */
  conditions?: string
  /**
   * The quantity of the line item
   */
  quantity: number
  /**
   * The price of the line item
   */
  unitPrice: number
  /**
   * Tax rate category
   */
  taxRate?: TaxRate
  /**
   * The discount price for the line item in percentage (multiplier) default 0.00
   */
  discount?: Discount
  /**
   * The total amount of the line item
   */
  amount: number
}

/**
 * The type of discount
 */
export type DiscountType = 'PERCENTAGE' | 'AMOUNT'

/**
 * The discount type values
 */
export enum DiscountTypeValues {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export type Discount = {
  /**
   * The type of discount
   */
  type: DiscountType
  /**
   * The value of the discount
   */
  value: number
}

/**
 * The quantity type values
 */
export enum QuantityTypeValues {
  PERCENTAGE = 'percentage',
  AMOUNT = 'amount',
}

/**
 * The quantity types
 */
export type QuantityType = QuantityTypeValues.AMOUNT | QuantityTypeValues.PERCENTAGE

/**
 * Defines a tax rate category
 */
export type TaxRate = {
  /**
   * Category of the tax rate
   */
  label: string
  /**
   * Percentage - multiplier 0 to 1
   */
  value: number
}

/**
 * Defines a region
 */
export type Region =
  | 'Africa'
  | 'Antarctica'
  | 'Arabian Gulf'
  | 'Australasia & South Pacific'
  | 'Caribbean'
  | 'East Mediterranean'
  | 'Indian Ocean & South East Asia'
  | 'North America'
  | 'Northern Europe'
  | 'South & Central America'
  | 'West Mediterranean'

/**
 * Defines a season
 */
export type Season = {
  /**
   * The season name
   */
  label: string
  /**
   * A description for the season label
   */
  description?: string
  /**
   * ISO-8601 string representation for a time interval
   */
  intervals: string[]
}

/**
 * Defines a calendar event
 */
export type CalendarEvent = {
  /**
   * Time based UUID for an event
   * Time of creation of the event
   */
  id: string
  /**
   * Title of the event
   * iCal spec - event title
   */
  title: string
  /**
   * Show the title of the event
   */
  showTitle?: boolean
  /**
   * The start and end dateTime of the event in ISO-8601 format split by '/'
   */
  interval: string
  /**
   * Visual indicator that the event is an all day event
   * Don't show the time of the event
   */
  allDay?: boolean
  /**
   * The type of event
   */
  eventType: EventType
  /**
   * Provision for a user to add a reference to the event (links, bookingId etc.)
   */
  reference?: string
  /**
   * The variant id of the event
   */
  variantId?: string
  /**
   * The variant label of the event at the time of creation
   */
  variantLabel?: string
  /**
   * The turnaround duration of the event, inferred from the variant
   */
  turnaroundDuration?: string
  /**
   * The place where the event starts
   */
  embark?: Place
  /**
   * The place where the event ends
   */
  disembark?: Place
  /**
   * The notes for the event
   * The event description. iCal spec - event description
   */
  notes?: string
}

export enum EventTypeEnums {
  BOOKED = 'Booked',
  TENTATIVE = 'Tentative/Option',
  MAINTENANCE = 'Maintenance',
  IN_TRANSIT = 'In transit',
  FLEXIBLE_USE = 'Flexible use',
  BOAT_SHOW = 'Boat show',
  OTHER = 'Other',
}

/**
 * Defines the type of event
 */
export type EventType =
  | EventTypeEnums.BOOKED
  | EventTypeEnums.TENTATIVE
  | EventTypeEnums.MAINTENANCE
  | EventTypeEnums.IN_TRANSIT
  | EventTypeEnums.FLEXIBLE_USE
  | EventTypeEnums.BOAT_SHOW
  | EventTypeEnums.OTHER

/**
 * Defines a place
 * This is a stripped down version of the place object in the itinerary
 * @openapi
 * components:
 *   schemas:
 *     Place:
 *       type: object
 *       properties:
 *         uri:
 *           type: string
 *           description: The URI of the place if relevant
 *           example: 'a::place::xxx'
 *           nullable: true
 *         name:
 *           type: string
 *           description: The name of the place
 *           example: 'St. Tropez'
 *         coordinates:
 *           type: object
 *           properties:
 *             latitude:
 *               type: number
 *               description: Number representing the latitude coordinate
 *               example: 7.123456
 *             longitude:
 *               type: number
 *               description: Number representing the longitude coordinate
 *               example: 7.123456
 *         country:
 *           type: string
 *           description: The country where the place is located
 *           example: 'France'
 */
export type Place = {
  /**
   * The URI of the place if relevant
   * If it is an Ankor / Custom place, then this will be populated
   */
  uri?: string
  /**
   * The name of the place
   */
  name: string
  /**
   * The geolocation of the place
   */
  coordinates: Coordinates
  /**
   * The country where the place is located
   */
  country: string
}

/**
 * The coordinates object structure
 * @openapi
 * components:
 *   schemas:
 *     Coordinates:
 *       type: object
 *       properties:
 *         latitude:
 *           type: number
 *           description: Number representing the latitude coordinate
 *           example: 7.123456
 *         longitude:
 *           type: number
 *           description: Number representing the longitude coordinate
 *           example: 7.123456
 */
export type Coordinates = {
  /**
   * Number representing the latitude coordinate
   */
  latitude: number
  /**
   * Number representing the longitude coordinate
   */
  longitude: number
}

/**
 * Represents a geographical location document used in the GeoFilters. Accessible in stowage via the a::geo::xxx
 * uri type
 *
 * @typedef {Object} Geo
 * @property {string} upstream - The upstream source of the geographical data.
 * @property {GeoJSON} geojson - The GeoJSON representation of the location.
 * @property {AlgoliaCoordinates[]} _geoloc - The array of AlgoliaCoordinates representing the coordinates of the location.
 */
export type Geo = {
  upstream: string
  geojson: GeoJSON
  _geoloc: AlgoliaCoordinates[]
}

/**
 * Defines a geo filter
 */
export type GeoFilter = {
  label: string
  uri: string
  /**
   * Possible values: intersection, union
   */
  operation: GeoFilterOperation | string
}

export enum GeoFilterOperation {
  INTERSECTION = 'intersection',
  UNION = 'union',
}
