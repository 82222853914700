<script setup lang="ts">
import { Ref, ref } from 'vue'

import { SolidChevronDown, SolidCircleQuestion } from '@ankor-io/icons/solid'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'

import { DisclaimersSectionData } from './types/types'

type Props = {
  id: string
  data: DisclaimersSectionData
  layout: LayoutTemplate
}

const props = defineProps<Props>()

const openIndex: Ref<number | null> = ref(null)
const toggleAccordion = (index: number): void => {
  if (openIndex.value === index) {
    openIndex.value = null
  } else {
    openIndex.value = index
  }
}
</script>
<template>
  <div>
    <div v-if="props.layout.type === 'open'" id="disclaimer-open" class="flex flex-col gap-y-14">
      <div
        v-for="(disclaimer, disclaimerIndex) in props.data.disclaimers"
        class="flex flex-col gap-y-5"
        :key="disclaimerIndex"
      >
        <div class="flex gap-x-2.5">
          <SolidCircleQuestion class="size-5 flex-shrink-0 icon-theme-subheading" />
          <h6>
            <SingleLineTextEditor
              class="font-medium"
              placeholder="Add a disclaimer title"
              :value="disclaimer.type"
              :isEditable="false"
            />
          </h6>
        </div>
        <MultiLineTextEditor
          class="font-medium"
          placeholder="Add a disclaimer description"
          :value="disclaimer.disclaimer"
          :isEditable="false"
        />
      </div>
    </div>

    <!-- Accordion -->
    <div v-else id="disclaimer-accordion" class="flex flex-col">
      <template v-for="(disclaimer, disclaimerIndex) in props.data.disclaimers" :key="disclaimerIndex">
        <div class="flex gap-x-5">
          <div class="w-full">
            <button
              type="button"
              class="peer flex justify-between items-center w-full p-5 font-medium text-left border border-gray-200 hover:bg-gray-100"
              aria-expanded="true"
              :class="{
                'rounded-t-xl': disclaimerIndex === 0,
                'rounded-b-xl': disclaimerIndex === props.data.disclaimers.length - 1 && openIndex !== disclaimerIndex,
                'bg-gray-100': openIndex === disclaimerIndex,
              }"
              @click="toggleAccordion(disclaimerIndex)"
            >
              <div class="flex gap-x-2.5">
                <SolidCircleQuestion class="size-5 flex-shrink-0 transition-all icon-theme-subheading" />
                <h6>
                  <SingleLineTextEditor
                    class="font-medium"
                    placeholder="Add a disclaimer title"
                    :value="disclaimer.type"
                    :isEditable="false"
                  />
                </h6>
              </div>
              <SolidChevronDown
                class="w-5 icon-fill-theme-subheading transition-all duration-500"
                :class="{ 'rotate-180': openIndex === disclaimerIndex }"
              />
            </button>
            <div
              v-show="openIndex === disclaimerIndex"
              class="p-5 border border-gray-200 transition-all duration-300 ease-out overflow-hidden"
            >
              <MultiLineTextEditor
                class="font-medium"
                placeholder="Add a disclaimer description"
                :value="disclaimer.disclaimer"
                :isEditable="false"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
