<script setup lang="ts">
import { Ref, ref } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'

type Props = {
  /**
   * The place name
   */
  name: string
  /**
   * The place description
   */
  description: string
  /**
   * The location image
   */
  image: string
  /**
   * Represents the segment label
   */
  label: string
}

const props = defineProps<Props>()
const isClamped: Ref<boolean> = ref(true)

const toggleClamp = () => {
  isClamped.value = !isClamped.value
}
</script>
<template>
  <div class="flex flex-row w-full bg-gray-50 rounded-lg">
    <div
      class="bg-white p-4 border border-gray-200 dark:border-gray-600 shadow-lg rounded-lg min-w-[18.75rem] max-w-[18.75rem] max-h-61 h-full w-full"
    >
      <AssetViewer
        v-if="props.image"
        class="w-full h-full object-cover"
        loading-strategy="eager"
        :url="`/media/${props.image}`"
        :width-descriptors="['640w']"
      />
    </div>
    <div class="px-8 py-4 flex flex-col gap-y-4">
      <div class="flex flex-wrap gap-x-1 gap-y-2 mt-4">
        <h1 class="!text-3xl font-semibold !text-gray-300 leading-6">{{ props.label }}.</h1>
        <h1 class="!text-3xl font-normal location-card-heading self-center break-all">
          <SingleLineTextEditor :value="props.name" :is-editable="false" />
        </h1>
      </div>
      <hr class="w-20 border-2 border-theme-primary" />
      <template v-if="props.description">
        <p :class="isClamped ? 'line-clamp-4' : 'line-clamp-none'">
          <MultiLineTextEditor class="location-card-body" :value="props.description" :is-editable="false" />
        </p>
        <span v-if="isClamped" class="cursor-pointer text-xs text-theme-primary" @click="toggleClamp"> Show more </span>
        <span v-else class="cursor-pointer text-xs text-theme-primary" @click="toggleClamp"> Show less </span>
      </template>
    </div>
  </div>
</template>
<style>
.location-card-heading .ProseMirror p {
  line-height: 1.5rem; /* TailwindCSS would not override the text editors line height */
}

.location-card-body .ProseMirror p {
  line-height: 1.375; /* TailwindCSS would not override the text editors line height */
}
</style>
