<script lang="ts" setup>
/**
 * ###############################################
 * ################### Input #####################
 * id - Proposal ID
 * uri - URI of the vessel entity
 * data - {@VesselHeroData} - Vessel hero data
 * layout - {@LayoutTemplate} - Layout template
 * ################### Output ####################
 * Renders vessel hero section (editable)
 * ###############################################
 */
import { ComputedRef, Ref, computed, inject, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import ImageCarouselModalEditor from '@ankor-io/blocks/components/ImageCarouselModal/ImageCarouselModalEditor.vue'
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { OutlinePhoto } from '@ankor-io/icons/outline'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { AuthenticationContext } from '@/iam/types'
import { LayoutTemplate } from '@/sections/types'
import { VesselHeroData } from '@/sections/vessel/hero/types'
import { linkMedia } from '@/services/MediaService'

type Props = {
  /**
   * The section id
   */
  id: string
  /**
   * The slide uri
   */
  uri: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The section's data
   */
  data: VesselHeroData
  /**
   * The section's layout
   */
  layout: LayoutTemplate
  /**
   * The lifecycle hook
   */
  lifecycle: Runnable<EditableLifecycleHooks>
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<VesselHeroData>): void
}>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

props.lifecycle({
  onHydrated: async () => {
    if (props.data.images?.length) {
      await linkMedia({ authenticationContext }, props.proposalUri, props.data.images)
    }
  },
} as unknown as EditableLifecycleHooks)

const imagePaths: ComputedRef<string[]> = computed(
  () => (props.data.images?.length && replacePathToMediaUris(props.proposalUri, ...props.data.images)) || [],
)

const showCarouselModal: Ref<boolean> = ref(false)

/**
 * Update vessel name
 * @param value
 */
const updateName = (value: string) => {
  emit('update:value', {
    sectionId: props.id,
    data: {
      ...props.data,
      name: value,
    },
  })
}

/**
 * Update vessel type
 * @param value
 */
const updateType = (value: string) => {
  emit('update:value', {
    sectionId: props.id,
    data: {
      ...props.data,
      type: value,
    },
  })
}

/**
 * Update vessel length
 * @param value
 */
const updateLength = (value: string) => {
  emit('update:value', {
    sectionId: props.id,
    data: {
      ...props.data,
      length: value,
    },
  })
}

/**
 * Update vessel cabins
 * @param value
 */
const updateCabins = (value: string) => {
  emit('update:value', {
    sectionId: props.id,
    data: {
      ...props.data,
      cabins: value,
    },
  })
}

/**
 * Update vessel model
 * @param value
 */
const updateModel = (value: string) => {
  emit('update:value', {
    sectionId: props.id,
    data: {
      ...props.data,
      model: value,
    },
  })
}

/**
 * Update vessel sleeps
 * @param value
 */
const updateSleeps = (value: string) => {
  emit('update:value', {
    sectionId: props.id,
    data: {
      ...props.data,
      sleeps: value,
    },
  })
}
</script>
<template>
  <section class="antialiased text-center">
    <!-- Vessel name -->
    <h1 class="text-2xl @sm:text-3xl font-bold mb-4">
      <SingleLineTextEditor :value="props.data.name" placeholder="Vessel name" @update:value="updateName($event)" />
    </h1>

    <!-- Vessel type -->
    <h5 class="text-xl">
      <SingleLineTextEditor :value="props.data.type" placeholder="Vessel Type" @update:value="updateType($event)" />
    </h5>

    <div class="flex justify-center mt-4">
      <hr class="border-t-2 my-1 border-theme-primary w-28" />
    </div>

    <!-- Featured Items -->
    <div class="relative z-10 mx-3 mt-3 select-none">
      <dl
        class="inline-flex flex-wrap bg-white justify-center p-4 shadow-lg gap-2 @sm:gap-8 rounded-lg border border-gray-200 dark:border-gray-600"
      >
        <div class="flex flex-col justify-center items-center">
          <p>Length</p>
          <h1 class="!text-base @sm:!text-2xl font-bold">
            <SingleLineTextEditor
              :value="props.data.length"
              placeholder="0m/0ft"
              @update:value="updateLength($event)"
            />
          </h1>
        </div>
        <div class="flex flex-col justify-center items-center">
          <p>Sleeps</p>
          <h1 class="!text-base @sm:!text-2xl font-bold">
            <SingleLineTextEditor :value="props.data.sleeps" placeholder="0" @update:value="updateSleeps($event)" />
          </h1>
        </div>
        <div class="flex flex-col justify-center items-center">
          <p>Cabins</p>
          <h1 class="!text-base @sm:!text-2xl font-bold">
            <SingleLineTextEditor :value="props.data.cabins" placeholder="0" @update:value="updateCabins($event)" />
          </h1>
        </div>
        <div class="flex flex-col justify-center items-center">
          <p>Builder</p>
          <h1 class="!text-base @sm:!text-2xl font-bold">
            <SingleLineTextEditor :value="props.data.builder" placeholder="-" @update:value="updateModel($event)" />
          </h1>
        </div>
      </dl>
    </div>

    <!-- Vessel Gallery -->
    <div class="relative -mt-6">
      <div class="h-[22.5rem] sm:h-full">
        <AssetViewerPlaceholder
          class="w-full overflow-hidden rounded-2xl col-span-2 object-cover"
          :url="`/media/${imagePaths?.[0]}`"
        />
      </div>

      <!-- Show all button -->
      <button
        v-if="imagePaths.length"
        type="button"
        class="absolute bottom-8 right-8 sm:bottom-8 sm:right-8 z-10 text-gray-900 bg-white hover:bg-gray-100 border border-gray-900 focus:outline-none font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
        @click="showCarouselModal = true"
      >
        <OutlinePhoto class="inline-block w-4 h-4 stroke-2 mr-2" />
        Show all photos
      </button>
    </div>
  </section>

  <Teleport v-if="showCarouselModal" to="body">
    <ImageCarouselModalEditor :slides="imagePaths" @close="showCarouselModal = false" />
  </Teleport>
</template>
