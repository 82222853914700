<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { FunctionalComponent, ref } from 'vue'

import AnkorLogoGradient from '@/assets/AnkorLogoGradient.svg'

export type NavLink = { name: string; href: string; icon?: FunctionalComponent; active?: boolean; onClick?: () => void }
type Props = {
  navigation: NavLink[]
  apps: NavLink[]
  navBottom: NavLink[]
  classNames?: { sidebar: string; content: string }
}
const props = defineProps<Props>()

const sidebarOpen = ref(false)
</script>

<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog class="relative z-50 xl:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80"></div>
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="size-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- left aside sidebar -->
              <slot name="leftAside">
                <div
                  class="flex grow flex-col gap-y-5 overflow-y-auto p-4 border-r bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-600"
                >
                  <a href="/">
                    <!-- This is the SolidAnkorLogo, however you must bake in the gradient directly which doesn't apply when importing an icon -->
                    <img :src="AnkorLogoGradient" class="size-8" />
                  </a>
                  <slot name="nav">
                    <nav class="flex flex-1 flex-col">
                      <ul role="list" class="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" class="space-y-1">
                            <li v-for="item in props.navigation" :key="item.name">
                              <slot name="navItem" :item="item">
                                <RouterLink :to="item.href" @click="sidebarOpen = false">
                                  <span
                                    :class="[
                                      item.active
                                        ? 'bg-gray-100 dark:bg-gray-700'
                                        : 'transition-colors hover:bg-gray-100 hover:dark:bg-gray-700 hover:text-gray-800 dark:hover:text-gray-200',
                                      'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 dark:text-gray-300',
                                    ]"
                                  >
                                    <component
                                      aria-hidden="true"
                                      :is="item.icon"
                                      :class="[
                                        item.active
                                          ? 'text-gray-900 dark:text-white'
                                          : 'text-gray-500 dark:text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-200',
                                        'transition-colors size-6 shrink-0',
                                      ]"
                                    />
                                    {{ item.name }}
                                  </span>
                                </RouterLink>
                              </slot>
                            </li>
                          </ul>
                        </li>
                        <slot name="appGroup">
                          <li>
                            <slot name="appGroupTitle"></slot>
                            <ul role="list" class="space-y-1">
                              <li v-for="item in props.apps" :key="item.name" @click="item.onClick">
                                <slot name="appGroupItem" :item="item">
                                  <a :href="item.href" target="_blank">
                                    <span
                                      class="transition-colors group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-100 hover:dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200"
                                    >
                                      <component
                                        aria-hidden="true"
                                        class="transition-colors size-6 shrink-0 text-gray-500 dark:text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-200"
                                        :is="item.icon"
                                      />
                                      {{ item.name }}
                                    </span>
                                  </a>
                                </slot>
                              </li>
                            </ul>
                          </li>
                        </slot>
                        <slot name="navBottom">
                          <li
                            v-if="props.navBottom.length"
                            class="mt-auto border-t border-gray-200 dark:border-gray-700 pt-8"
                          >
                            <ul role="list" class="space-y-1">
                              <li v-for="item in props.navBottom" :key="item.name" @click="item.onClick">
                                <slot name="navBottomItem" :item="item">
                                  <a :href="item.href" target="_blank">
                                    <span
                                      class="transition-colors group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-100 hover:dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200"
                                    >
                                      <component
                                        aria-hidden="true"
                                        class="transition-colors size-6 shrink-0 text-gray-500 dark:text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-200"
                                        :is="item.icon"
                                      />
                                      {{ item.name }}
                                    </span>
                                  </a>
                                </slot>
                              </li>
                            </ul>
                          </li>
                        </slot>
                      </ul>
                    </nav>
                  </slot>
                </div>
              </slot>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- xl: left aside -->
    <div class="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col" :class="[props.classNames?.sidebar]">
      <slot name="leftAside">
        <div
          class="flex grow flex-col gap-y-5 overflow-y-auto p-4 border-r bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-600"
        >
          <a href="/">
            <!-- This is the SolidAnkorLogo, however you must bake in the gradient directly which doesn't apply when importing an icon -->
            <img :src="AnkorLogoGradient" class="size-8" />
          </a>
          <slot name="nav">
            <nav class="flex flex-1 flex-col">
              <ul role="list" class="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" class="space-y-1">
                    <li v-for="item in props.navigation" :key="item.name">
                      <slot name="navItem" :item="item">
                        <RouterLink :to="item.href">
                          <span
                            :class="[
                              item.active
                                ? 'bg-gray-100 dark:bg-gray-700'
                                : 'transition-colors hover:bg-gray-100 hover:dark:bg-gray-700 hover:text-gray-800 dark:hover:text-gray-200',
                              'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 dark:text-gray-300',
                            ]"
                          >
                            <component
                              aria-hidden="true"
                              :is="item.icon"
                              :class="[
                                item.active
                                  ? 'text-gray-900 dark:text-white'
                                  : 'text-gray-500 dark:text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-200',
                                'transition-colors size-6 shrink-0',
                              ]"
                            />
                            {{ item.name }}
                          </span>
                        </RouterLink>
                      </slot>
                    </li>
                  </ul>
                </li>
                <slot name="appGroup">
                  <li>
                    <slot name="appGroupTitle"></slot>
                    <ul role="list" class="space-y-1">
                      <li v-for="item in props.apps" :key="item.name" @click="item.onClick">
                        <slot name="appGroupItem" :item="item">
                          <a :href="item.href" target="_blank">
                            <span
                              class="transition-colors group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-100 hover:dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200"
                            >
                              <component
                                aria-hidden="true"
                                class="transition-colors size-6 shrink-0 text-gray-500 dark:text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-200"
                                :is="item.icon"
                              />
                              {{ item.name }}
                            </span>
                          </a>
                        </slot>
                      </li>
                    </ul>
                  </li>
                </slot>
                <slot name="navBottom">
                  <li v-if="props.navBottom.length" class="mt-auto border-t border-gray-200 dark:border-gray-700 pt-8">
                    <ul role="list" class="space-y-1">
                      <li v-for="item in props.navBottom" :key="item.name" @click="item.onClick">
                        <slot name="navBottomItem" :item="item">
                          <a :href="item.href" target="_blank">
                            <span
                              class="transition-colors group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-100 hover:dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200"
                            >
                              <component
                                aria-hidden="true"
                                class="transition-colors size-6 shrink-0 text-gray-500 dark:text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-200"
                                :is="item.icon"
                              />
                              {{ item.name }}
                            </span>
                          </a>
                        </slot>
                      </li>
                    </ul>
                  </li>
                </slot>
              </ul>
            </nav>
          </slot>
        </div>
      </slot>
    </div>

    <div class="xl:pl-72 grid grid-rows-[auto_1fr] overflow-auto" :class="[props.classNames?.content || 'h-screen']">
      <div
        class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-r px-4 shadow-sm sm:gap-x-6 sm:px-6 xl:px-8 border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800"
      >
        <div class="flex gap-x-2">
          <button
            type="button"
            class="-m-2.5 p-2.5 text-gray-500 dark:text-gray-400 xl:hidden"
            @click="sidebarOpen = true"
          >
            <span class="sr-only">Open sidebar</span>
            <Bars3Icon class="size-6" aria-hidden="true" />
          </button>
          <RouterLink
            class="block xl:hidden p-1 rounded-lg transition-all hover:scale-110 padding-4 focus:bg-gray-50 active:bg-gray-50 dark:focus:bg-gray-900 dark:active:bg-gray-900"
            :to="{ name: 'trips-library-presentations' }"
          >
            <!-- This is the SolidAnkorLogo, however you must bake in the gradient directly which doesn't apply when importing an icon -->
            <img :src="AnkorLogoGradient" class="size-8" />
          </RouterLink>
        </div>

        <!-- Separator -->
        <div class="h-6 w-px bg-gray-900/10 xl:hidden" aria-hidden="true"></div>

        <slot name="header"></slot>
      </div>

      <main class="py-10 bg-gray-50 dark:bg-gray-900">
        <div class="px-4 sm:px-6 xl:px-8">
          <!-- Content -->
          <slot></slot>
        </div>
      </main>
    </div>
  </div>
</template>
