<script setup lang="ts">
import { Ref, computed, inject, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { SolidDotsSquare } from '@ankor-io/icons/solid'

import Spinner from '@/components/Spinner.vue'
import AssetDropOnly from '@/components/asset-uploader/AssetDropOnly.vue'
import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { AuthenticationContext } from '@/iam/types'
import { OverviewSectionEditorData } from '@/sections/overview/OverviewSectionEditor'
import { LayoutTemplate } from '@/sections/types'
import { linkMedia } from '@/services/MediaService'
import { removeDragCursor } from '@/utils/dragcursor'
import { WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE } from '@/utils/growthbook/constants'

type Props = {
  id: string
  uri: string
  proposalUri: string
  data: OverviewSectionEditorData
  layout: LayoutTemplate
  lifecycle: Runnable<EditableLifecycleHooks>
  isHydrating: boolean
}

const props = defineProps<Props>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const uploadState: Ref<string | null> = ref(null)
const draggedImage: Ref<string | null> = ref(null)
const dragoverElement: Ref<string | null> = ref(null)

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<OverviewSectionEditorData>): void
}>()

props.lifecycle({
  onHydrated: async () => {
    if (props.data.images?.length) {
      await linkMedia({ authenticationContext }, props.proposalUri, props.data.images)
    }
  },
} as unknown as EditableLifecycleHooks)

const update = (data: OverviewSectionEditorData): void => {
  emit('update:value', { sectionId: props.id, data })
}

const drag = (event: DragEvent): void => {
  if (draggedImage.value !== null) return
  const target = event.target as HTMLElement
  draggedImage.value = target.id
}

const dragover = (event: DragEvent): void => {
  const target = event.target as HTMLElement
  // dropzoneLabel is the incorrect target, so ignore it
  if (dragoverElement.value === target.id || target.id.includes('dropzoneLabel')) {
    return
  }

  if (target.tagName === 'svg') {
    dragoverElement.value = target.parentElement!.id
  } else {
    dragoverElement.value = target.id
  }
}

const drop = (id?: string): void => {
  removeDragCursor()
  if (id) {
    const element = document.getElementById(id) as HTMLImageElement
    const selectedImage = element.dataset.imagePath || element.src
    const url = new URL(selectedImage, window.location.origin)
    const mediaUri = url.pathname.split('/')[2]
    emit('update:value', { sectionId: props.id, data: { ...props.data, selectedImage: mediaUri } })
    draggedImage.value = null
    dragoverElement.value = null
  }
}

const imageUri = computed(() => {
  if (!props.data.image) {
    return ''
  }

  return replacePathToMediaUris(props.proposalUri, props.data.image)[0]
})

const selectedImageUri = computed(() => {
  if (!props.data.selectedImage) {
    return ''
  }

  return replacePathToMediaUris(props.proposalUri, props.data.selectedImage)[0]
})
</script>
<template>
  <div v-if="isHydrating" class="h-44 flex items-center justify-center">
    <Spinner />
  </div>
  <div v-else>
    <!-- Image and text layout (with dots/graphics) -->
    <div v-if="props.layout.type === 'image_and_text'">
      <div
        class="relative mx-auto"
        :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:p-28' : 'sm:p-28'"
      >
        <!-- Dots -->
        <SolidDotsSquare
          class="fill-theme-accent absolute w-32 top-8 -left-[3.70rem]"
          :class="
            $growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE)
              ? '@sm:w-[17rem] @sm:top-0 @sm:left-0'
              : 'sm:w-[17rem] sm:top-0 sm:left-0'
          "
        />
        <SolidDotsSquare
          class="fill-theme-accent absolute w-32 bottom-10 -right-8"
          :class="
            $growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE)
              ? '@sm:w-[17rem] @sm:bottom-0 @sm:right-0'
              : 'sm:w-[17rem] sm:bottom-0 sm:right-0'
          "
        />

        <div
          class="flex flex-col"
          :class="
            $growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE)
              ? '@sm:grid @sm:grid-cols-2'
              : 'sm:grid sm:grid-cols-[1fr_1fr]'
          "
        >
          <!-- Image -->
          <AssetDropOnly
            class="relative z-10"
            :image-index="0"
            :upload-state="uploadState"
            @file:dropped="drop"
            @image:dragover="dragover"
          >
            <div class="max-h-[38.7rem] h-full z-10">
              <AssetViewerPlaceholder
                class="h-full w-full object-cover rounded-lg"
                :url="`/media/${selectedImageUri || imageUri}`"
                @drag="drag"
              />
            </div>
          </AssetDropOnly>

          <!-- Text -->
          <div
            class="flex relative w-[calc(50% + 2rem)] z-20 -mt-4"
            :class="
              $growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE)
                ? '@sm:-ml-8 @sm:mt-0 @sm:py-8'
                : 'sm:-ml-8 sm:mt-0 sm:py-8'
            "
          >
            <div
              class="h-full grow shadow-lg bg-white rounded-lg border border-gray-200 dark:border-gray-600 p-4"
              :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:p-8' : 'sm:p-8'"
            >
              <!-- Heading -->
              <h1 class="overview-heading text-5xl mb-4">
                <SingleLineTextEditor
                  placeholder="Enter a heading"
                  :value="props.data.heading || props.data.name || props.data.overviewHeading"
                  @update:value="update({ ...props.data, heading: $event })"
                />
              </h1>

              <!-- Subheading -->
              <h3
                class="overview-subheading text-xl mb-4"
                :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:mb-8' : 'sm:mb-8'"
              >
                <SingleLineTextEditor
                  class="mb-4"
                  placeholder="Enter a subheading"
                  :value="props.data.subheading || props.data.internalName || props.data.externalName"
                  @update:value="update({ ...props.data, subheading: $event })"
                />
                <hr class="bg-theme-accent border-none w-16 h-[3px]" />
              </h3>

              <!-- Narrative -->
              <div class="min-h-[10rem] max-h-[22rem] overflow-auto" style="word-break: break-word">
                <MultiLineTextEditor
                  class="gap-10 font-light"
                  placeholder="Enter an overview"
                  :value="props.data.narrative || props.data.description"
                  @update:value="update({ ...props.data, narrative: $event })"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Image and text layout (with no dots/graphics) -->
    <div v-else-if="props.layout.type === 'image_and_text_no_graphics'">
      <div class="relative mx-auto">
        <div
          class="flex flex-col"
          :class="
            $growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE)
              ? '@sm:grid @sm:grid-cols-2'
              : 'sm:grid sm:grid-cols-[1fr_1fr]'
          "
        >
          <!-- Image -->
          <AssetDropOnly
            class="relative z-10"
            :image-index="0"
            :upload-state="uploadState"
            @file:dropped="drop"
            @image:dragover="dragover"
          >
            <div class="max-h-[38.7rem] h-full z-10">
              <AssetViewerPlaceholder
                class="h-full w-full object-cover rounded-lg"
                :url="`/media/${selectedImageUri || imageUri}`"
                @drag="drag"
              />
            </div>
          </AssetDropOnly>

          <!-- Text -->
          <div
            class="flex relative w-[calc(50% + 2rem)] z-20 -mt-4"
            :class="
              $growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE)
                ? '@sm:-ml-8 @sm:mt-0 @sm:py-8'
                : 'sm:-ml-8 sm:mt-0 sm:py-8'
            "
          >
            <div
              class="h-full grow shadow-lg bg-white rounded-lg border border-gray-200 dark:border-gray-600 p-4"
              :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:p-8' : 'sm:p-8'"
            >
              <!-- Heading -->
              <h1 class="overview-heading text-5xl mb-4">
                <SingleLineTextEditor
                  placeholder="Enter a heading"
                  :value="props.data.heading || props.data.name || props.data.overviewHeading"
                  @update:value="update({ ...props.data, heading: $event })"
                />
              </h1>

              <!-- Subheading -->
              <h3
                class="overview-subheading text-xl mb-4"
                :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:mb-8' : 'sm:mb-8'"
              >
                <SingleLineTextEditor
                  class="mb-4"
                  placeholder="Enter a subheading"
                  :value="props.data.subheading || props.data.internalName || props.data.externalName"
                  @update:value="update({ ...props.data, subheading: $event })"
                />
                <hr class="bg-theme-accent border-none w-16 h-[3px]" />
              </h3>

              <!-- Narrative -->
              <div class="min-h-[10rem] max-h-[22rem] overflow-auto" style="word-break: break-word">
                <MultiLineTextEditor
                  class="gap-10 font-light"
                  placeholder="Enter an overview"
                  :value="props.data.narrative || props.data.description"
                  @update:value="update({ ...props.data, narrative: $event })"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Two column layout -->
    <div v-else-if="props.layout.type === 'two_column'">
      <!-- Heading -->
      <h1 class="overview-heading text-5xl mb-2.5">
        <SingleLineTextEditor
          placeholder="Enter a heading"
          :value="props.data.heading || props.data.name || props.data.overviewHeading"
          @update:value="update({ ...props.data, heading: $event })"
        />
      </h1>
      <!-- Subheading -->
      <h3
        class="overview-subheading text-xl mb-4"
        :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:mb-12' : 'sm:mb-12'"
      >
        <SingleLineTextEditor
          placeholder="Enter a subheading"
          :value="props.data.subheading || props.data.internalName || props.data.externalName"
          @update:value="update({ ...props.data, subheading: $event })"
        />
      </h3>
      <!-- Narrative -->
      <p
        class="gap-10 font-light leading-normal"
        :class="
          $growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:columns-2' : 'sm:columns-2'
        "
      >
        <MultiLineTextEditor
          placeholder="Enter an overview"
          :value="props.data.narrative || props.data.description"
          @update:value="update({ ...props.data, narrative: $event })"
        />
      </p>
    </div>

    <!-- Single column layout -->
    <div v-else>
      <!-- Heading -->
      <h1 class="overview-heading text-5xl mb-2.5">
        <SingleLineTextEditor
          placeholder="Enter a heading"
          :value="props.data.heading || props.data.name || props.data.overviewHeading"
          @update:value="update({ ...props.data, heading: $event })"
        />
      </h1>
      <!-- Subheading -->
      <h3
        class="overview-subheading text-xl mb-4"
        :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:mb-12' : 'sm:mb-12'"
      >
        <SingleLineTextEditor
          placeholder="Enter a subheading"
          :value="props.data.subheading || props.data.internalName || props.data.externalName"
          @update:value="update({ ...props.data, subheading: $event })"
        />
      </h3>
      <!-- Narrative -->
      <MultiLineTextEditor
        class="gap-10 font-light leading-normal"
        placeholder="Enter an overview"
        :value="props.data.narrative || props.data.description"
        @update:value="update({ ...props.data, narrative: $event })"
      />
    </div>
  </div>
</template>
<style>
.overview-heading .ProseMirror p {
  line-height: 46px; /* TailwindCSS would not override the text editors line height */
}
.overview-subheading .ProseMirror p {
  line-height: 24px; /* TailwindCSS would not override the text editors line height */
}
</style>
